import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment, isMoment } from 'moment-timezone';
import { DateFormat } from '@utils/dates/formats';

type Props<T extends FieldValues> = {
  control: Control<T>;
  name: Path<T>;
  label: string;
  required?: boolean;
} & DatePickerProps<Moment>;

export const ControlledDatePicker: <T extends FieldValues>(props: Props<T>) => JSX.Element = ({
  control,
  name,
  label,
  required = false,
  ...datePickerProps
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, fieldState }): JSX.Element => (
        <DatePicker
          label={label}
          value={isMoment(value) ? value : moment(value)}
          format={DateFormat}
          onChange={onChange}
          slotProps={{
            textField: {
              error: Boolean(fieldState.error),
              required,
              fullWidth: true,
              helperText: fieldState.error?.message,
            },
          }}
          {...datePickerProps}
        />
      )}
    />
  );
};
