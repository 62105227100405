import React, { useEffect, useRef, useState } from 'react';
import { IReportTemplateField } from './report-template-field.interface';
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
  type RichTextEditorRef,
} from 'mui-tiptap';
import StarterKit from '@tiptap/starter-kit';
import { FormControl, InputLabel } from '@mui/material';
import { Control, Controller } from 'react-hook-form';
import { INeedsAssessmentReport } from '../report.interface';

type Props = {
  field: IReportTemplateField;
  control: Control<Partial<INeedsAssessmentReport>>;
};

export const ReportTemplateRTEField: React.FunctionComponent<Props> = ({ field, control }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const rteRef = useRef<RichTextEditorRef>(null);
  const editor = rteRef.current?.editor;

  // eslint-disable-next-line no-underscore-dangle
  const formValue = control._defaultValues[field.fieldName];
  const fieldValue = field.defaultValue;
  const initialContent = (formValue ?? fieldValue) as string;

  // This helps restore saved in local storage value to editor and make it editable again
  useEffect(() => {
    if (!editor || editor.isDestroyed) {
      return;
    }
    if (!editor.isFocused || !editor.isEditable) {
      queueMicrotask(() => {
        const currentSelection = editor.state.selection;
        editor.chain().setContent(initialContent).setTextSelection(currentSelection).run();
        setIsEditable(true);
      });
    }
  }, [initialContent, editor]);

  return (
    <Controller
      control={control}
      name={field.fieldName}
      render={({ field: { onChange } }): JSX.Element => (
        <FormControl fullWidth>
          <InputLabel shrink focused={isFocused} className="MuiInputLabel-rte">
            {field.fieldLabel}
          </InputLabel>
          <RichTextEditor
            ref={rteRef}
            extensions={[StarterKit]}
            content={initialContent}
            editable={isEditable}
            onUpdate={({ editor }): void => onChange(editor.getHTML())}
            onFocus={(): void => setIsFocused(true)}
            onBlur={(): void => setIsFocused(false)}
            renderControls={(): JSX.Element => (
              <MenuControlsContainer>
                <MenuSelectHeading />
                <MenuDivider />
                <MenuButtonBold />
                <MenuButtonItalic />
              </MenuControlsContainer>
            )}
          />
        </FormControl>
      )}
    />
  );
};
