import React from 'react';
import { Button } from '@mui/material';
import { useSubmitReport } from './use-submit-report';

type Props = {
  orderId: number;
};

export const SubmitReport: React.FunctionComponent<Props> = ({ orderId }) => {
  const { handleSubmit } = useSubmitReport(orderId);
  return (
    <Button variant="contained" onClick={handleSubmit}>
      Submit Report
    </Button>
  );
};
