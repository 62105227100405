import { useEffect, useState } from 'react';
import { useGetReportTemplateQuery } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { ReportTemplateActions, selectTemplateBySlug } from './report-template.slice';
import { IReportTemplateSection } from './report-template-section.interface';

type HookResult = RequestStatusFlags & {
  sectionTemplate: IReportTemplateSection | null;
  studentId: number;
  quoteId: string;
};

export function useReportSectionTemplate(slug: string, orderId: number): HookResult {
  const { data, ...flags } = useGetReportTemplateQuery(orderId);
  const [studentId, setStudentId] = useState(0);
  const [quoteId, setQuoteId] = useState('');

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(ReportTemplateActions.upsertMany(data.sections));
      setStudentId(data.studentId);
      setQuoteId(data.quoteInternalId);
    }
  }, [data, dispatch]);

  const sectionTemplate = useAppSelector((state) => selectTemplateBySlug(state, slug)) ?? null;

  return { sectionTemplate, studentId, quoteId, ...flags };
}
