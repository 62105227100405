import useLocalStorage from 'use-local-storage';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { ReportLocalStorageKey } from './report-local-storage-key';
import { INeedsAssessmentReport } from './report.interface';
import { IReportTemplateField } from './fields/report-template-field.interface';
import { useDebounce } from 'use-debounce';
import { useEffect } from 'react';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { useCreateReportTemplateMutation } from '@api/api-slice';
import { useAppSelector } from '@store/use-app-selector';
import { selectAll } from './report-template.slice';

type HookResult = {
  handleSubmit: () => unknown;
};

export function useSubmitReport(orderId: number): HookResult {
  const [mutation, flags] = useCreateReportTemplateMutation();

  const sections = useAppSelector((state) => selectAll(state));
  const fields = sections
    .map((section) => section.fields)
    .flat()
    .reduce((a, c) => ({ ...a, [c.fieldName]: c.defaultValue }), {});
  const [savedValues] = useLocalStorage(`${ReportLocalStorageKey}/${orderId}`, {});

  const payload = {
    ...fields,
    ...savedValues,
  };

  async function handleSubmit(): Promise<unknown> {
    const result = await mutation({ orderId, payload }).unwrap();
    return result;
  }

  return {
    handleSubmit,
  };
}
