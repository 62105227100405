import React from 'react';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

type Props = {
  orderId: number;
};

export const StudentInformation: React.FunctionComponent<Props> = ({ orderId }) => {
  return (
    <Box>
      <Typography>Student Information</Typography>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              Name
            </TableCell>
            <TableCell component="th" scope="row">
              Here First and Last Name
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Email
            </TableCell>
            <TableCell component="th" scope="row">
              Here Email
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Funding Body
            </TableCell>
            <TableCell component="th" scope="row">
              Here First Name
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              Customer Reference Number
            </TableCell>
            <TableCell component="th" scope="row">
              Here Customer Reference Number
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
};
