import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import { Routes } from '@routes/routes';
import { reportNavigation } from './report-navigation';

type Props = {
  slug: string;
  orderId: number;
};

/** Renders prev and next links if available for the section */
export const ReportPager: React.FunctionComponent<Props> = ({ slug, orderId }) => {
  const link = reportNavigation[slug];
  return (
    <Stack direction="row" sx={{ marginTop: 'auto' }}>
      {link.prev && (
        <NavLink to={`${Routes.report}/${orderId}/${link.prev}`} className="report-navigation-link">
          <Button variant="outlined">Prev</Button>
        </NavLink>
      )}
      {link.next && (
        <NavLink
          to={`${Routes.report}/${orderId}/${link.next}`}
          style={{ marginLeft: 'auto' }}
          className="report-navigation-link"
        >
          <Button variant="contained">Confirm and Next</Button>
        </NavLink>
      )}
    </Stack>
  );
};
