import { createEnumOptions } from '@utils/enum/create-enum.factory';

export const Ways = {
  In: 'in',
  Out: 'out',
  System: 'system',
  Internal: 'internal',
  User: 'user',
} as const;

type Keys = keyof typeof Ways;

export type WayType = (typeof Ways)[Keys];

export const WayOptions = createEnumOptions(Ways);
