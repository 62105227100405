import React from 'react';
import { ReportNavigation } from './ReportNavigation';
import { Alert, Box, Link, Stack, Switch, Typography } from '@mui/material';
import { ReportSection } from './ReportSection';
import { useReportSectionTemplate } from './use-report-section-template';
import { ReportPager } from './ReportPager';
import { AppPaper } from '@components/AppPaper';
import { SubmitReport } from './SubmitReport';
import { useToggle } from '@hooks/use-toggle';
import { Routes } from '@routes/routes';
import { NavLink } from 'react-router-dom';

const minWidth = '320px';

type Props = {
  slug: string;
  orderId: number;
};

export const ReportBuilder: React.FunctionComponent<Props> = ({ slug, orderId }) => {
  const { sectionTemplate, studentId, quoteId } = useReportSectionTemplate(slug, orderId);

  const [isPreview, togglePreview] = useToggle();

  if (!sectionTemplate) {
    return <Alert severity="error">No template found</Alert>;
  }

  return (
    <AppPaper>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4" component="h1">
          Need Assessment Report for order
          <Link component={NavLink} to={`${Routes.orderSummaries}/${studentId}/${orderId}`}>
            {studentId}/{orderId}
          </Link>
        </Typography>
        <Box>
          Preview
          <Switch value={isPreview} onChange={togglePreview} />
          <SubmitReport orderId={orderId} />
        </Box>
      </Stack>
      <Stack direction="row" sx={{ height: '100%', mt: 1 }} spacing={2}>
        <Box sx={{ flexBasis: minWidth, minWidth }}>
          <ReportNavigation slug={slug} orderId={orderId} />
        </Box>
        <Stack direction="column" sx={{ height: '100%', flexGrow: 1 }}>
          <ReportSection
            sectionTemplate={sectionTemplate}
            slug={slug}
            orderId={orderId}
            isPreview={isPreview}
            quoteId={quoteId}
          />
          <ReportPager slug={slug} orderId={orderId} />
        </Stack>
        {sectionTemplate.fundingBodyGuidance && (
          <Box sx={{ maxWidth: '320px' }}>
            <Typography variant="h5" component="h2">
              Funding Body Guidance
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: sectionTemplate.fundingBodyGuidance }}></div>
          </Box>
        )}
      </Stack>
    </AppPaper>
  );
};
