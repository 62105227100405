import React from 'react';
import { Control } from 'react-hook-form';
import { ControlledDatePicker } from '@components/inputs/ControlledDatePicker';
import { INeedsAssessmentReport } from '../report.interface';
import { IReportTemplateField } from './report-template-field.interface';

type Props = {
  field: IReportTemplateField;
  control: Control<Partial<INeedsAssessmentReport>>;
};

export const ReportTemplateDateField: React.FunctionComponent<Props> = ({ field, control }) => {
  const { fieldLabel, fieldName, isEditable, description } = field;

  return (
    <ControlledDatePicker
      control={control}
      label={fieldLabel}
      name={fieldName}
      disabled={!isEditable}
      // helperText={description}
    />
  );
};
