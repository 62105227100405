import React, { useEffect } from 'react';
import { Alert } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteParamsType } from '@routes/route-params.type';
import { reportNavigation } from './report-navigation';
import { Routes } from '@routes/routes';
import { ReportBuilder } from './ReportBuilder';

export const ReportBuilderPage: React.FunctionComponent = () => {
  const { reportSlugString, reportOrderIdString } = useParams<RouteParamsType>();
  const navigate = useNavigate();

  useEffect(() => {
    if (reportSlugString && reportSlugString in reportNavigation && reportNavigation[reportSlugString].redirectSlug) {
      navigate(`${Routes.report}/${reportOrderIdString}/${reportNavigation[reportSlugString].redirectSlug}`);
    }
  }, [navigate, reportOrderIdString, reportSlugString]);

  const orderId = reportOrderIdString ? parseInt(reportOrderIdString, 10) : null;

  if (!orderId) {
    return <Alert severity="error">No order id found</Alert>;
  }

  if (!reportSlugString) {
    return <Alert severity="error">No slug found</Alert>;
  }

  return <ReportBuilder slug={reportSlugString} orderId={orderId} />;
};
