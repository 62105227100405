import { ReportQuote } from './ReportQuote';
import { DummyPreview } from './preview/DummyPreview';
import { StudentInformation } from './preview/StudentInformation';

type TerminatorConfig = {
  title: string;
  slug: string;
  redirectSlug: string;
  parentSlugs: string[];
  ExtraComponent?: never;
  PreviewComponent?: never;
  prev?: never;
  next?: never;
};

type PageConfig = {
  title: string;
  slug: string;
  redirectSlug?: never;
  parentSlugs: string[];
  ExtraComponent?: React.ComponentType<any>;
  PreviewComponent: React.ComponentType<any>;
  prev: string | null;
  next: string | null;
};

export type SectionConfig = TerminatorConfig | PageConfig;

export const reportNavigation: { [slug: string]: SectionConfig } = {
  title: {
    title: 'Title Page',
    slug: 'title',
    redirectSlug: 'student-information',
    parentSlugs: [],
  },
  'student-information': {
    title: 'Student Information',
    slug: 'student-information',
    parentSlugs: ['title'],
    prev: null,
    next: 'assessment-information',
    PreviewComponent: StudentInformation,
  },
  'assessment-information': {
    title: 'Assessment Information',
    slug: 'assessment-information',
    parentSlugs: ['title'],
    prev: 'student-information',
    next: 'course-information',
    PreviewComponent: DummyPreview,
  },
  'course-information': {
    title: 'Course Information',
    slug: 'course-information',
    parentSlugs: ['title'],
    prev: 'assessment-information',
    next: 'statement-of-aims',
    PreviewComponent: DummyPreview,
  },
  'statement-of-aims': {
    title: 'Statement of Aims',
    slug: 'statement-of-aims',
    parentSlugs: ['title'],
    prev: 'course-information',
    next: 'a-1-list-of-recommended-support',
    PreviewComponent: DummyPreview,
  },
  summary: {
    title: 'A. Summary',
    slug: 'summary',
    redirectSlug: 'a-1-list-of-recommended-support',
    parentSlugs: [],
  },
  'a-1-list-of-recommended-support': {
    title: 'A-1. List of Recommended Support',
    slug: 'a-1-list-of-recommended-support',
    parentSlugs: ['summary'],
    prev: 'statement-of-aims',
    next: 'a-2-student-summary',
    PreviewComponent: DummyPreview,
  },
  'a-2-student-summary': {
    title: 'A-2. Student Summary',
    slug: 'a-2-student-summary',
    parentSlugs: ['summary'],
    prev: 'a-1-list-of-recommended-support',
    next: 'b-1-disability-information',
    PreviewComponent: DummyPreview,
  },
  'background-information': {
    title: 'B. Background Information',
    slug: 'background-information',
    redirectSlug: 'b-1-disability-information',
    parentSlugs: [],
  },
  'b-1-disability-information': {
    title: 'B-1. Disability information',
    slug: 'b-1-disability-information',
    parentSlugs: ['background-information'],
    prev: 'a-2-student-summary',
    next: 'b-2-details-of-previous-dsa',
    PreviewComponent: DummyPreview,
  },
  'b-2-details-of-previous-dsa': {
    title: 'B-2. Details of previous DSA',
    slug: 'b-2-details-of-previous-dsa',
    parentSlugs: ['background-information'],
    prev: 'b-1-disability-information',
    next: 'b-3-course-details',
    PreviewComponent: DummyPreview,
  },
  'b-3-course-details': {
    title: 'B-3. Course Details',
    slug: 'b-3-course-details',
    parentSlugs: ['background-information'],
    prev: 'b-2-details-of-previous-dsa',
    next: 'b-4-higher-education-provider-support',
    PreviewComponent: DummyPreview,
  },
  'b-4-higher-education-provider-support': {
    title: 'B-4. Higher education provider support',
    slug: 'b-4-higher-education-provider-support',
    parentSlugs: ['background-information'],
    prev: 'b-3-course-details',
    next: 'c-1-strategies-equipment-previously-used-currently-in-use',
    PreviewComponent: DummyPreview,
  },
  'effects-of-disability-and-recommendations': {
    title: 'C. Effects of Disability and Recommendations',
    slug: 'effects-of-disability-and-recommendations',
    redirectSlug: 'c-1-strategies-equipment-previously-used-currently-in-use',
    parentSlugs: [],
  },
  'c-1-strategies-equipment-previously-used-currently-in-use': {
    title: 'C-1. Strategies/equipment previously used/currently in use',
    slug: 'c-1-strategies-equipment-previously-used-currently-in-use',
    parentSlugs: ['effects-of-disability-and-recommendations'],
    prev: 'b-4-higher-education-provider-support',
    next: 'c-2-1-research-and-reading',
    PreviewComponent: DummyPreview,
  },
  'c-2-impacts-of-disability-and-recommended-strategies': {
    title: 'C-2. Impacts of disability and recommended strategies',
    slug: 'c-2-impacts-of-disability-and-recommended-strategies',
    redirectSlug: 'c-2-1-research-and-reading',
    parentSlugs: ['effects-of-disability-and-recommendations'],
  },
  'c-2-1-research-and-reading': {
    title: 'C-2-1. Research and reading',
    slug: 'c-2-1-research-and-reading',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-1-strategies-equipment-previously-used-currently-in-use',
    next: 'c-2-2-writing-and-reviewing-academic-work',
    PreviewComponent: DummyPreview,
    ExtraComponent: ReportQuote,
  },
  'c-2-2-writing-and-reviewing-academic-work': {
    title: 'C-2-2. Writing and reviewing academic work',
    slug: 'c-2-2-writing-and-reviewing-academic-work',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-1-research-and-reading',
    next: 'c-2-3-note-taking-in-lectures-and-seminars',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-3-note-taking-in-lectures-and-seminars': {
    title: 'C-2-3. Note-taking in lectures and seminars',
    slug: 'c-2-3-note-taking-in-lectures-and-seminars',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-2-writing-and-reviewing-academic-work',
    next: 'c-2-4-managing-time-and-organising-work',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-4-managing-time-and-organising-work': {
    title: 'C-2-4. Managing time and organising work',
    slug: 'c-2-4-managing-time-and-organising-work',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-3-note-taking-in-lectures-and-seminars',
    next: 'c-2-5-access-to-and-use-of-technology',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-5-access-to-and-use-of-technology': {
    title: 'C-2-5. Access to and use of technology',
    slug: 'c-2-5-access-to-and-use-of-technology',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-4-managing-time-and-organising-work',
    next: 'c-2-6-practical-sessions,-placements,-field-trips-and-additional-course-activities',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-6-practical-sessions,-placements,-field-trips-and-additional-course-activities': {
    title: 'C-2-6. Practical sessions, placements, field trips and additional course activities',
    slug: 'c-2-6-practical-sessions,-placements,-field-trips-and-additional-course-activities',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-5-access-to-and-use-of-technology',
    next: 'c-2-7-examinations-and-timed-assessments',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-7-examinations-and-timed-assessments': {
    title: 'C-2-7. Examinations and timed assessments',
    slug: 'c-2-7-examinations-and-timed-assessments',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-6-practical-sessions,-placements,-field-trips-and-additional-course-activities',
    next: 'c-2-8-social-interaction-and-communication',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-8-social-interaction-and-communication': {
    title: 'C-2-8. Social interaction and communication',
    slug: 'c-2-8-social-interaction-and-communication',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-7-examinations-and-timed-assessments',
    next: 'c-2-9-travel-and-access-to-higher-education-environment',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-9-travel-and-access-to-higher-education-environment': {
    title: 'C-2-9. Travel and access to higher education environment',
    slug: 'c-2-9-travel-and-access-to-higher-education-environment',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-8-social-interaction-and-communication',
    next: 'c-2-10-additional-information',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  'c-2-10-additional-information': {
    title: 'C-2-10. Additional information',
    slug: 'c-2-10-additional-information',
    parentSlugs: ['effects-of-disability-and-recommendations', 'c-2-impacts-of-disability-and-recommended-strategies'],
    prev: 'c-2-9-travel-and-access-to-higher-education-environment',
    next: 'd-1-equipment-quote-breakdown-in-appendix-1',
    ExtraComponent: ReportQuote,
    PreviewComponent: DummyPreview,
  },
  costs: {
    title: 'D. Costs',
    slug: 'costs',
    redirectSlug: 'd-1-equipment-quote-breakdown-in-appendix-1',
    parentSlugs: [],
  },
  'd-1-equipment-quote-breakdown-in-appendix-1': {
    title: 'D-1. Equipment (Quote breakdown in Appendix 1)',
    slug: 'd-1-equipment-quote-breakdown-in-appendix-1',
    parentSlugs: ['costs'],
    prev: 'c-2-10-additional-information',
    next: 'd-2-assistive-technology-training',
    PreviewComponent: DummyPreview,
  },
  'd-2-assistive-technology-training': {
    title: 'D-2. Assistive Technology Training',
    slug: 'd-2-assistive-technology-training',
    parentSlugs: ['costs'],
    prev: 'd-1-equipment-quote-breakdown-in-appendix-1',
    next: 'd-3-non-medical-help',
    PreviewComponent: DummyPreview,
  },
  'd-3-non-medical-help': {
    title: 'D-3. Non-Medical Help',
    slug: 'd-3-non-medical-help',
    parentSlugs: ['costs'],
    prev: 'd-2-assistive-technology-training',
    next: 'd-4-general-support',
    PreviewComponent: DummyPreview,
  },
  'd-4-general-support': {
    title: 'D-4. General Support',
    slug: 'd-4-general-support',
    parentSlugs: ['costs'],
    prev: 'd-3-non-medical-help',
    next: 'd-5-needs-assessment-fee',
    PreviewComponent: DummyPreview,
  },
  'd-5-needs-assessment-fee': {
    title: 'D-5. Needs Assessment Fee',
    slug: 'd-5-needs-assessment-fee',
    parentSlugs: ['costs'],
    prev: 'd-4-general-support',
    next: 'd-6-accommodation',
    PreviewComponent: DummyPreview,
  },
  'd-6-accommodation': {
    title: 'D-6. Accommodation',
    slug: 'd-6-accommodation',
    parentSlugs: ['costs'],
    prev: 'd-5-needs-assessment-fee',
    next: 'd-7-travel-taxi-costs',
    PreviewComponent: DummyPreview,
  },
  'd-7-travel-taxi-costs': {
    title: 'D-7. Travel - Taxi costs',
    slug: 'd-7-travel-taxi-costs',
    parentSlugs: ['costs'],
    prev: 'd-6-accommodation',
    next: 'd-8-travel-mileage-costs',
    PreviewComponent: DummyPreview,
  },
  'd-8-travel-mileage-costs': {
    title: 'D-8. Travel - Mileage costs',
    slug: 'd-8-travel-mileage-costs',
    parentSlugs: ['costs'],
    prev: 'd-7-travel-taxi-costs',
    next: 'd-9-needs-assessor-requests-preferred-supplier',
    PreviewComponent: DummyPreview,
  },
  'd-9-needs-assessor-requests-preferred-supplier': {
    title: 'D-9. Needs assessor requests/preferred supplier',
    slug: 'd-9-needs-assessor-requests-preferred-supplier',
    parentSlugs: ['costs'],
    prev: 'd-8-travel-mileage-costs',
    next: 'd-10-summary-of-costs',
    PreviewComponent: DummyPreview,
  },
  'd-10-summary-of-costs': {
    title: 'D-10. Summary of costs',
    slug: 'd-10-summary-of-costs',
    parentSlugs: ['costs'],
    prev: 'd-9-needs-assessor-requests-preferred-supplier',
    next: 'e-1-higher-education-provider-support',
    PreviewComponent: DummyPreview,
  },
  'higher-education-provider-support': {
    title: 'E. Higher Education Provider Support',
    slug: 'higher-education-provider-support',
    redirectSlug: 'e-1-higher-education-provider-support',
    parentSlugs: [],
  },
  'e-1-higher-education-provider-support': {
    title: 'E-1. Higher education provider support',
    slug: 'e-1-higher-education-provider-support',
    parentSlugs: ['higher-education-provider-support'],
    prev: 'd-10-summary-of-costs',
    next: 'e-2-examinations-and-assessment',
    PreviewComponent: DummyPreview,
  },
  'e-2-examinations-and-assessment': {
    title: 'E-2. Examinations and assessment',
    slug: 'e-2-examinations-and-assessment',
    parentSlugs: ['higher-education-provider-support'],
    prev: 'e-1-higher-education-provider-support',
    next: 'e-3-other-advice-and-guidance',
    PreviewComponent: DummyPreview,
  },
  'e-3-other-advice-and-guidance': {
    title: 'E-3. Other advice and guidance',
    slug: 'e-3-other-advice-and-guidance',
    parentSlugs: ['higher-education-provider-support'],
    prev: 'e-2-examinations-and-assessment',
    next: 'contact-details',
    PreviewComponent: DummyPreview,
  },
  'contact-details': {
    title: 'F. Contact Details',
    slug: 'contact-details',
    parentSlugs: [],
    prev: 'e-3-other-advice-and-guidance',
    next: 'appendix-1-equipment-quote',
    PreviewComponent: DummyPreview,
  },
  'appendix-1-equipment-quote': {
    title: 'Appendix 1: Equipment Quote',
    slug: 'appendix-1-equipment-quote',
    parentSlugs: [],
    prev: 'contact-details',
    next: null,
    PreviewComponent: DummyPreview,
  },
} as const;
