import React from 'react';
import { Box } from '@mui/material';

type Props = {
  orderId: number;
  quoteId: string;
};

export const ReportQuote: React.FunctionComponent<Props> = ({ quoteId }) => {
  return <Box>Renders a Quote Products for order: {quoteId}</Box>;
};
