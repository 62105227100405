import React from 'react';
import { Control } from 'react-hook-form';
import { Box } from '@mui/material';
import { INeedsAssessmentReport } from '../report.interface';
import { ReportTemplateFieldTypes } from './report-template-field-type.type';
import { IReportTemplateField } from './report-template-field.interface';
import { ReportTemplateTextField } from './ReportTemplateTextField';
import { ReportTemplateRTEField } from './ReportTemplateRTEField';
import { ReportTemplateSelectField } from './ReportTemplateSelectField';
import { ReportTemplateDateField } from './ReportTemplateDateField';
import { ReportTemplateBooleanField } from './ReportTemplateBooleanField';

type Props = {
  field: IReportTemplateField;
  control: Control<Partial<INeedsAssessmentReport>>;
};

const ReportTemplateFieldBase: React.FunctionComponent<Props> = ({ field, control }) => {
  switch (field.fieldType) {
    case ReportTemplateFieldTypes.TextField:
      return <ReportTemplateTextField field={field} control={control} />;
    case ReportTemplateFieldTypes.Table:
      return <ReportTemplateTextField field={field} control={control} />;
    case ReportTemplateFieldTypes.Select:
      return <ReportTemplateSelectField field={field} control={control} />;
    case ReportTemplateFieldTypes.Date:
      return <ReportTemplateDateField field={field} control={control} />;
    case ReportTemplateFieldTypes.Boolean:
      return <ReportTemplateBooleanField field={field} control={control} />;
    case ReportTemplateFieldTypes.Linked:
      return <ReportTemplateTextField field={field} control={control} />;
    case ReportTemplateFieldTypes.RTE:
      return <ReportTemplateRTEField field={field} control={control} />;
  }
};

export const ReportTemplateField: React.FunctionComponent<Props> = ({ field, control }) => {
  return (
    <Box>
      <ReportTemplateFieldBase field={field} control={control} />
    </Box>
  );
};
