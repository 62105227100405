import useLocalStorage from 'use-local-storage';
import { FieldValues, UseFormReturn, useForm } from 'react-hook-form';
import { ReportLocalStorageKey } from './report-local-storage-key';
import { INeedsAssessmentReport } from './report.interface';
import { IReportTemplateField } from './fields/report-template-field.interface';
import { useDebounce } from 'use-debounce';
import { useEffect } from 'react';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';

type HookResult<FormPageModel extends FieldValues> = {
  // submitHandler: React.FormEventHandler;
  // isButtonDisabled: boolean;
  // isDropdownsLoading: boolean;
} & UseFormReturn<FormPageModel>;

type FormPageModel = Partial<INeedsAssessmentReport>;

export function useReportSectionForm(
  fields: IReportTemplateField[],
  slug: string,
  orderId: number,
): HookResult<FormPageModel> {
  const [savedValues, setSavedValues] = useLocalStorage<FormPageModel>(
    `${ReportLocalStorageKey}/${orderId}`,
    {},
    {
      syncData: true,
    },
  );

  const values = createInitialValues(fields, savedValues);

  const useFormResult = useForm<FormPageModel>({ defaultValues: values });
  const { watch, reset } = useFormResult;
  const watchedValues = watch();
  const watchedString = JSON.stringify(watchedValues);

  // переписать на дебаунс колбеке, затем чтобы сбрасывать дебаунс при смене слага
  const [debouncedFormValues] = useDebounce(watchedString, 1000);

  useEffect(() => {
    const filtered = filterEmptyObjectFields(watchedValues);
    setSavedValues({ ...savedValues, ...filtered });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFormValues]);

  useEffect(() => {
    reset(values);
    // Here we want to reset form only on slug change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return useFormResult;
}

function createInitialValues(fields: IReportTemplateField[], savedValues: FormPageModel): FormPageModel {
  const values: FormPageModel = {};
  fields.forEach(({ fieldName: key, defaultValue }) => {
    if (savedValues[key]) {
      values[key] = savedValues[key];
    } else {
      values[key] = defaultValue ?? '';
    }
  });
  return values;
}
