import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import { applyTokenFromState } from '@api/http-query-client';
import { userReducer } from '@features/user/user.slice';
import { zohoReducer } from '@features/zoho-authorization/zoho.slice';
import { ordersReducer } from '@features/order/orders.slice';
import { customerReducer } from '@features/customer/customer.slice';
import { allocationsReducer } from '@features/allocations/allocations.slice';
import { sessionsReducer } from '@features/sessions/sessions.slice';
import { orderContactRecordsReducer } from '@features/order/shared/order-action-records/order-contact-record.slice';
import { backendLabelsReducer } from '@features/backend-label/backend-labels.slice';
import { appointmentsReducer } from '@features/order/needs-assessment/appointment/appointments.slice';
import { reportsReducer } from '@features/order/needs-assessment/report/reports.slice';
import { apiSlice } from '@api/api-slice';
import { purgeOnLogoutMiddleware } from '@features/auth/logout.middleware';
import { microsoftReducer } from '@features/microsoft-authorization/microsoft.slice';
import { debounceObjectPayloadMiddleware } from './debounce.middleware';
import { productsReducer } from '@features/product/product.slice';
import { annualSupportChargeReducer } from '@features/order/annual-support-charge/annual-support-charge-list/annual-support-charge.slice';
import { reportTemplateReducer } from '@features/report-builder/report-template.slice';
import { genericInvoiceSummaryReducer } from '@features/bulk-invoicing/generic-invoice-summary.slice';

const rootReducer = combineReducers({
  user: persistReducer({ key: 'User', storage }, userReducer),
  labels: persistReducer({ key: 'Labels', storage }, backendLabelsReducer),
  zoho: zohoReducer,
  orders: ordersReducer,
  customer: customerReducer,
  allocations: allocationsReducer,
  sessions: sessionsReducer,
  orderContactRecords: orderContactRecordsReducer,
  appointments: appointmentsReducer,
  reports: reportsReducer,
  microsoft: microsoftReducer,
  products: productsReducer,
  annualSupportCharge: annualSupportChargeReducer,
  reportTemplates: reportTemplateReducer,
  genericInvoiceSummaries: genericInvoiceSummaryReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([apiSlice.middleware, purgeOnLogoutMiddleware, debounceObjectPayloadMiddleware]),
});

export const persistor = persistStore(store, null, applyTokenFromState);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const selectRootState = (state: RootState): RootState => state;
