import React from 'react';
import { NavLink } from 'react-router-dom';
import { Routes } from '@routes/routes';
import { Box, Link, MenuList } from '@mui/material';
import { reportNavigation } from './report-navigation';

type Props = {
  slug: string;
  orderId: number;
};

export const ReportNavigation: React.FC<Props> = ({ slug, orderId }) => {
  const activeChain = reportNavigation[slug]?.parentSlugs ?? [];
  const links = Object.values(reportNavigation);

  return (
    <MenuList sx={{ p: 0 }}>
      {links.map((link) => {
        if (link.parentSlugs.length > 0 && !link.parentSlugs.every((p, i) => activeChain[i] === p)) {
          return null;
        }

        return (
          <Box component="li" key={link.slug}>
            <Link
              component={NavLink}
              to={`${Routes.report}/${orderId}/${link.slug}`}
              sx={{
                display: 'block',
                pt: 0.75,
                pb: 0.75,
                pl: link.parentSlugs.length * 1.5,
                fontSize: 14,
                '&:hover': { bgcolor: 'grey.50' },
                '&.active': { bgcolor: 'grey.100' },
              }}
            >
              {link.title}
            </Link>
          </Box>
        );
      })}
    </MenuList>
  );
};
