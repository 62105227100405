import { isEmpty, isNil } from 'lodash';
import { TransportableModel } from '@models/transportable.type';

/**
 * Return new object without null, undefined or empty array fields
 * Works with flat objects
 */
export function filterEmptyObjectFields<T extends Partial<TransportableModel<T>>>(object: T): Partial<T> {
  const result: Partial<T> = {};

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key];
      const isNotNullish = !isNil(value);
      const isNotEmptyArray = !(Array.isArray(value) && isEmpty(value));
      const isNotEmptyString = typeof value === 'string' && value.trim().length > 0;
      if (isNotNullish && isNotEmptyArray && isNotEmptyString) {
        result[key] = value;
      }
    }
  }

  return result;
}
