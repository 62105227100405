export const ReportTemplateFieldTypes = {
  TextField: 'text-field',
  RTE: 'RTE',
  Table: 'table',
  Select: 'select',
  Date: 'date',
  Boolean: 'boolean',
  Linked: 'linked',
} as const;

type Keys = keyof typeof ReportTemplateFieldTypes;

export type ReportTemplateFieldType = (typeof ReportTemplateFieldTypes)[Keys];
