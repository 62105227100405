import React from 'react';
import { Box } from '@mui/material';

type Props = {
  orderId: number;
};

export const DummyPreview: React.FunctionComponent<Props> = ({ orderId }) => {
  return <Box>Renders a Dummy Preview order: {orderId}</Box>;
};
