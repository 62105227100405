import React from 'react';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Routes } from '@routes/routes';
import { RouteParams } from '@routes/route-params.type';
import { DashboardPage } from '@features/dashboard/DashboardPage';
import { DeliveryCalendarPage } from '@features/delivery-calendar/DeliveryCalendarPage';
import { KPIPage } from '@features/kpi/KPIPage';
import { Layout } from '@routes/Layout';
import { SessionListPage } from '@features/sessions/SessionListPage';
import { OrderSummariesPage } from '@features/order-summaries/OrderSummariesPage';
import { CustomerPage } from '@features/customer/CustomerPage';
import { TutorListPage } from '@features/tutors/TutorListPage';
import { TutorPage } from '@features/tutors/TutorPage';
import { AllocationsPage } from '@features/allocations/AllocationsPage';
import { AssessorListPage } from '@features/assessors/AssessorListPage';
import { Reset } from '@features/auth/reset/Reset';
import { RequireAuth } from '@features/auth/RequireAuth';
import { Login } from '@features/auth/login/Login';
import { ProductListPage } from '@features/product/ProductListPage';
import { ProductPage } from '@features/product/ProductPage';
import { QuoteListPage } from '@features/quote/QuoteListPage';
import { QuotePage } from '@features/quote/QuotePage';
import { AppointmentSummariesPage } from '@features/appointment-summaries/AppointmentSummariesPage';
import { AssessorPage } from '@features/assessors/AssessorPage';
import { AssessorUtilisationPage } from '@features/assessor-utilisation/AssessorUtilisationPage';
import { AssessmentCentreListPage } from '@features/assessment-centres/AssessmentCentreListPage';
import { AssessmentCentrePage } from '@features/assessment-centres/AssessmentCentrePage';
import { EquipmentInvoicingPage } from '@features/equipment-invoicing/EquipmentInvoicingPage';
import { HepPage } from '@features/hep/HepPage';
import { HepListPage } from '@features/hep/HepListPage';
import { BulkInvoicingPage } from '@features/bulk-invoicing/BulkInvoicingPage';
import { BulkAppointmentInvoicingPage } from '@features/bulk-invoicing/appointment/BulkAppointmentInvoicingPage';
import { BulkSessionInvoicingPage } from '@features/bulk-invoicing/session/BulkSessionInvoicingPage';
import { BulkEquipmentInvoicingPage } from '@features/bulk-invoicing/equipment/BulkEquipmentInvoicingPage';
import { BulkAnnualSupportChargeInvoicingPage } from '@features/bulk-invoicing/annual-support-charge/BulkAnnualSupportChargeInvoicingPage';
import { NotFoundPage } from './NotFoundPage';
import { ReportBuilderPage } from '@features/report-builder/ReportBuilderPage';

export const Router: React.FC = () => {
  const router = createBrowserRouter([
    { path: '*', Component: NotFoundPage },
    { path: Routes.login, Component: RequireAuth(Login) },
    { path: Routes.reset, Component: RequireAuth(Reset) },
    {
      path: Routes.root,
      Component: RequireAuth(Layout),
      children: [
        { path: Routes.dashboard, Component: RequireAuth(DashboardPage) },
        { path: Routes.orderSummaries, Component: RequireAuth(OrderSummariesPage) },
        {
          path: `${Routes.orderSummaries}/:${RouteParams.customerId}/:${RouteParams.orderId}`,
          Component: RequireAuth(CustomerPage),
        },
        { path: `${Routes.orderSummaries}/:${RouteParams.customerId}`, Component: RequireAuth(CustomerPage) },
        { path: Routes.deliveryCalendar, Component: RequireAuth(DeliveryCalendarPage) },
        { path: Routes.allocations, Component: RequireAuth(AllocationsPage) },
        { path: Routes.sessions, Component: RequireAuth(SessionListPage) },
        { path: Routes.tutors, Component: RequireAuth(TutorListPage) },
        { path: `${Routes.tutors}/:${RouteParams.tutorId}`, Component: RequireAuth(TutorPage) },
        { path: Routes.products, Component: RequireAuth(ProductListPage) },
        { path: `${Routes.products}/:${RouteParams.productId}`, Component: RequireAuth(ProductPage) },
        { path: Routes.quotes, Component: RequireAuth(QuoteListPage) },
        { path: `${Routes.quotes}/:${RouteParams.quoteId}`, Component: RequireAuth(QuotePage) },
        { path: Routes.kpi, Component: RequireAuth(KPIPage) },
        { path: Routes.assessors, Component: RequireAuth(AssessorListPage) },
        { path: `${Routes.assessors}/:${RouteParams.assessorId}`, Component: RequireAuth(AssessorPage) },
        { path: `${Routes.myAvailability}`, Component: RequireAuth(AssessorPage) },
        { path: Routes.appointments, Component: RequireAuth(AppointmentSummariesPage) },
        { path: Routes.assessorUtilisation, Component: RequireAuth(AssessorUtilisationPage) },
        { path: Routes.assessmentCentres, Component: RequireAuth(AssessmentCentreListPage) },
        {
          path: `${Routes.assessmentCentres}/:${RouteParams.assessmentCentreId}`,
          Component: RequireAuth(AssessmentCentrePage),
        },
        { path: `${Routes.equipmentInvoicing}`, Component: RequireAuth(EquipmentInvoicingPage) },
        { path: Routes.heps, Component: RequireAuth(HepListPage) },
        { path: `${Routes.heps}/:${RouteParams.hepId}`, Component: RequireAuth(HepPage) },
        {
          path: `${Routes.bulkInvoicing}`,
          Component: RequireAuth(BulkInvoicingPage),
          children: [
            {
              path: `${Routes.bulkAppointmentInvoicing}`,
              Component: RequireAuth(BulkAppointmentInvoicingPage),
            },
            {
              path: `${Routes.bulkSessionInvoicing}`,
              Component: RequireAuth(BulkSessionInvoicingPage),
            },
            {
              path: `${Routes.bulkEquipmentInvoicing}`,
              Component: RequireAuth(BulkEquipmentInvoicingPage),
            },
            {
              path: `${Routes.bulkAnnualSupportChargeInvoicing}`,
              Component: RequireAuth(BulkAnnualSupportChargeInvoicingPage),
            },
          ],
        },
        { path: `${Routes.report}`, Component: RequireAuth(ReportBuilderPage) },
        { path: `${Routes.report}/:${RouteParams.reportOrderId}`, Component: RequireAuth(ReportBuilderPage) },
        {
          path: `${Routes.report}/:${RouteParams.reportOrderId}/:${RouteParams.reportSlug}`,
          Component: RequireAuth(ReportBuilderPage),
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};
