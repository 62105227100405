import { Describe, StructError, array, assert, boolean, enums, nullable, number, object, string } from 'superstruct';
import { TrafficLights } from '@models/traffic-light.type';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IProductWithCondition } from '../product.interface';
import { IProductWithConditionResponse } from '../api';
import { MoneyStruct } from '@utils/superstruct/money.struct';

const ProductDetailStruct: Describe<IProductWithCondition> = object({
  id: number(),
  productCode: string(),
  productCategoryId: nullable(number()),
  productCodeAs: nullable(string()),
  productCodeSst: nullable(string()),
  productName: string(),
  isActive: boolean(),
  amountInclVat: MoneyStruct,
  amountExVat: MoneyStruct,
  vatTreatment: nullable(string()),
  trafficLight: nullable(enums(Object.values(TrafficLights))),
  feedbackAverage: number(),
  productDescription: string(),
  allProductDisabilityTypes: array(
    object({
      disabilityType: string(),
      disabilityTypeId: number(),
      justifications: array(object({ justificationTitle: string(), justificationText: string() })),
    }),
  ),
  feedbackDetails: object({
    usersRatedOne: number(),
    usersRatedTwo: number(),
    usersRatedThree: number(),
    usersRatedFour: number(),
    usersRatedFive: number(),
  }),
});

export function validateProductDetail(response: IProductWithConditionResponse, url: string): void {
  try {
    assert(response.product, ProductDetailStruct); //TODO FIX
  } catch (error) {
    for (const failure of (error as StructError).failures()) {
      handleFailure(failure, url);
    }
  }
}
