import React from 'react';
import { Stack, Typography } from '@mui/material';
import { ReportTemplateField } from './fields/ReportTemplateField';
import { useReportSectionForm } from './use-report-section-form';
import { IReportTemplateSection } from './report-template-section.interface';
import { reportNavigation } from './report-navigation';

type Props = {
  slug: string;
  orderId: number;
  quoteId: string;
  sectionTemplate: IReportTemplateSection;
  isPreview: boolean;
};

export const ReportSection: React.FunctionComponent<Props> = ({
  slug,
  orderId,
  quoteId,
  sectionTemplate,
  isPreview,
}) => {
  const { control } = useReportSectionForm(sectionTemplate.fields ?? [], slug, orderId);

  const config = reportNavigation[slug];

  if (isPreview && reportNavigation[slug]) {
    const { PreviewComponent } = config;
    if (PreviewComponent) {
      return <PreviewComponent />;
    }
  }

  const { ExtraComponent } = config;

  return (
    <>
      <Typography variant="h5" component="h2">
        {sectionTemplate.title}
      </Typography>
      {sectionTemplate.description && (
        <Typography
          variant="body1"
          component="p"
          sx={{ mt: 2 }}
          dangerouslySetInnerHTML={{ __html: sectionTemplate.description }}
        />
      )}
      <Stack direction="column" spacing={1.5} sx={{ mt: 2 }}>
        {sectionTemplate.fields.map((field, index) => (
          <ReportTemplateField key={index.toString()} field={field} control={control} />
        ))}
      </Stack>
      {ExtraComponent !== undefined ? <ExtraComponent quoteId={quoteId} /> : null}
    </>
  );
};
