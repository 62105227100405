import { appMutationFactory, appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IReportTemplateSection } from './report-template-section.interface';

type Response = {
  'Student Finance England': IReportTemplateSection[];
  studentId: number;
  quoteInternalId: string;
};

type Return = {
  sections: IReportTemplateSection[];
  studentId: number;
  quoteInternalId: string;
};

export const buildGetReportTemplate = appQueryFactory<number, Return>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.getReportTemplate(orderId),
    method: 'GET',
  }),
  transformResponse(response: Response): Return {
    return {
      studentId: response.studentId,
      quoteInternalId: response.quoteInternalId,
      sections: response['Student Finance England'],
    };
  },
});

type Payload = {
  orderId: number;
  payload: any;
};
export const buildCreateReportTemplate = appMutationFactory<Payload, IReportTemplateSection[]>({
  query: ({ orderId, payload }) => ({
    url: EndpointUrlBuilder.createReport(orderId),
    method: 'POST',
    data: payload,
  }),
});
